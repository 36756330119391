import { render, staticRenderFns } from "./InsurancePlans.vue?vue&type=template&id=02d1f33f&"
import script from "./InsurancePlans.vue?vue&type=script&lang=ts&"
export * from "./InsurancePlans.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-staging/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02d1f33f')) {
      api.createRecord('02d1f33f', component.options)
    } else {
      api.reload('02d1f33f', component.options)
    }
    module.hot.accept("./InsurancePlans.vue?vue&type=template&id=02d1f33f&", function () {
      api.rerender('02d1f33f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/insurance/InsurancePlans.vue"
export default component.exports