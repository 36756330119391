var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-component", {
    attrs: {
      addEditLink: {
        name: "ins-plans-details",
        params: { id: _vm.formData.id ? _vm.formData.id : "new" },
      },
      isAddEditSidbar: false,
      getByIdOnEditFilter: function () {
        return false
      },
      itemsRepo: _vm.insurancePlansRepo,
      sidebarData: _vm.formData,
      isDataFromModel: false,
      fields: _vm.fields,
    },
    on: {
      "update:sidebarData": function ($event) {
        _vm.formData = $event
      },
      "update:sidebar-data": function ($event) {
        _vm.formData = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }